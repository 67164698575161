.condition-ol {
    margin: 5px;
}

ol.lower-list {
    counter-reset: roman-count;
}

ol.lower-list li {
    list-style: none;
}

ol.lower-list li:before {
    counter-increment: roman-count;
    margin-left: -12px;
    content: "(" counter(roman-count, lower-alpha) ") ";
}

#isl-2022-a1-block {
    font-size: 74px;
    margin-top: 20px;
}

.imo2011-4-div {
    font-size: 52px !important;
}

.imo2011-4-block1 {
    font-size: 55px;
    margin-top: 30px;
}

#usamo2008-6 {
    font-size: 47px !important;
}

#usamo2008-6-img-div {
    height: 330px;
    width: 100%;
}

.usamo2008-6-bracket {
    margin-left: -10px;
    margin-right: -10px;
}

.usamo2008-6-red {
    color: #D77F65;
}

.usamo2008-6-green {
    color: #D3FBD8;
}

#usamo2008-6-img {
    width: 60%;
    margin-top: -20px;
}

#INMO2015-3 {
    font-size: 70px !important;
}

.wiki-img-div {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
    margin: 30px 0;
}

#spherical-angle-example {
    height: auto;
    width: auto;
    max-height: 400px;
    max-width: 100%;
}

#spherical-angle-example-2 {
    height: auto;
    width: auto;
    max-height: 400px;
    max-width: 100%;
}

#incenter-excenter-lemma {
    height: auto;
    width: auto;
    max-height: 600px;
    max-width: 100%;
}

#incenter {
    height: auto;
    width: auto;
    max-height: 400px;
    max-width: 100%;
}

#excenter {
    height: auto;
    width: auto;
    max-height: 500px;
    max-width: 100%;
}

#power-of-a-point {
    height: auto;
    width: auto;
    max-height: 700px;
    max-width: 100%;
}

#power-of-a-point-2 {
    height: auto;
    width: auto;
    max-height: 500px;
    max-width: 100%;
}

#tangent-chord-theorem {
    height: auto;
    width: auto;
    max-height: 500px;
    max-width: 100%;
}

#inscribed-angle {
    height: auto;
    width: auto;
    max-height: 500px;
    max-width: 100%;
}

#japan-mo-2022-4 {
    font-size: 70px;
}

#japan-mo-2022-4-statement {
    font-size: 100px;
}

#itamo-2019-2 {
    font-size: 55px;
}

#itamo-2019-2-statement {
    font-size: 70px;
}

#cmc-2020-1-img {
    height: 500px;
    margin-top: -20px;
}

#romania-tst-2012-2-1 {
    font-size: 90px;
}

#gmo-2019-3-6 {
    font-size: 58px;
}

#gmo-2019-3-6-statement {
    font-size: 100px;
}

#isl-2015-n1-block {
    font-size: 65px;
}

#bulgaria-nmo-2014-4-block {
    font-size: 75px;
}

#isl2014-a4 {
    font-size: 45px;
}

#isl2014-a4-block {
    position: absolute;
    top: 390px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 70px;
}

#serbiamo-2016-6 {
    font-size: 50px;
}

#serbiamo-2016-6-block {
    font-size: 70px;
}

#belarus-tst-2016-3-block {
    font-size: 70px;
}

#olympiad-problem-1-title {
    font-size: 65px;
}

#imo-2009-5-block {
    font-size: 76px;
}

#imo-2014-1-block {
    font-size: 72px;
}

#olympiad-problem2-title {
    font-size: 60px;
}

#imo2021-2-block {
    font-size: 60px;
}

#usamo-2018-4-block {
    font-size: 89px;
    margin-top: -55px !important;
    margin-bottom: -55px !important;
}

#tn-usamo-2018-4 {
    font-size: 50px;
}

#taiwan-mo-2023-2-block {
    font-size: 80px;
}

#rmm-sl-2019-a1-block {
    font-size: 60px;
}

#turkey-mo-2021-5 {
    font-size: 50px;
}

#turkey-mo-2021-5-block {
    font-size: 75px;
}

#estonia-tst-2019-3-block {
    font-size: 62px;
}

.thumbnail-wide-div {
    height: 400px;
    font-size: 55px;
}

#theorem-1 {
    font-size: 90px;
}

#baltic-way-2021-6-block {
    font-size: 110px;
}

#japan-mo-2020-3 {
    margin-top: 50px;
    font-size: 58px;
}

#japan-mo-2023-4-1 {
    position: absolute;
    left: 100px;
    top: 120px;
    font-size: 90px;
}

#japan-mo-2023-4-2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 340px;
    font-size: 50px;
}

#japan-mo-2023-4-3 {
    position: absolute;
    right: 100px;
    top: 380px;
    font-size: 90px;
}

#tn-bwm-2019-1-4-2 {
    font-size: 90px;
}

#tn-egmo-2016-1-2 {
    margin-top: 40px;
    font-size: 60px;
}

#tn-india-egmo-tst-2023-4-2 {
    font-size: 90px;
    margin-top: -60px;
    margin-bottom: -40px;
}

#tn-cmo-2008-4-2 {
    font-size: 90px;
}

#tn-rmm-2023-6-1 {
    font-size: 55px;
}

#tn-rmm-2023-6-2 {
    margin-top: -50px;
    font-size: 80px;
}

#rmm-2023-6-img-div {
    height: 330px;
    width: 100%;
}

#rmm-2023-6-img {
    height: 100%;
    margin-top: -20px;
}

#rmm-2023-6-red {
    color: #C13D25;
}

#rmm-2023-6-green {
    color: #008B76;
}

#rmm-2023-6-blue {
    color: #0091EC;
}

#tn-romania-sup-tst-2004-2-1 {
    font-size: 42px;
}

#tn-romania-sup-tst-2004-2-2 {
    width: 90%;
    margin-top: 30px;
}

#tn-romania-sup-tst-2004-2-img {
    width: 58%;
    margin-top: -10px;
}

#complex-numbers-1 {
    font-size: 70px;
}

#complex-numbers-1-1 {
    margin-top: -100px;
    font-size: 150px;
}

#complex-numbers-1-title {
    font-size: 80px;
}

#complex-numbers-3-title {
    font-size: 80px;
}

#rmm-2023-1 {
    font-size: 110px;
}

#tn-china-tst-2018-2 {
    font-size: 90px;
}

#application-of-complex-numbers-in-combinatorics {
    margin-top: 40px;
    font-size: 75px;
}

#tn-cno-2022-6 {
    width: 100%;
}

#tn-cno-2022-6-img {
    width: 45%;
    transform: translate(-30px, 0px);
}

#china-tst-2018-2-tn {
    font-size: 52px;
}

#bmo2023-4-img {
    width: 50%;
}

#olympiad-problem-3-1 {
    font-size: 70px;
}

#bsl-2023-c5-img-div {
    margin-top: -100px;
}

#bsl-2023-c5-img {
    height: 900px;
}

#isl-2009-c1-img-div {
    margin-top: 30px;
}

.isl-2009-c1-img {
    height: 200px;
    padding: 10px;
}

#india-egmo-tst-2023-6-img-div {
    position: absolute;
    top: 120px;
    left: 600px;
}

#india-egmo-tst-2023-6-img {
    height: 570px;
}

#india-egmo-tst-2023-6-text-div {
    font-size: 80px;
    text-align: start;
    text-align: left;
    margin-left: 100px;
}

#tomo-2023-2-block-1 {
    font-size: 120px;
    margin-top: -80px;
}

#bsl-2023-c5-img-div {
    margin-top: 1px;
}

#bsl-2023-c5-img {
    height: 400px;
}

#pascals-theorem {
    height: 400px;
}

.imo-logo-div {
    position: absolute;
    right: 20px;
    top: 20px;
}

.imo-logo {
    width: 350px;
}

#imo-2023-2-div {
    margin-top: -10px;
}

#imo-2023-2 {
    width: 700px;
}

#imo-2023-3-div {
    font-size: 80px;
    margin-top: 80px;
}

.imo-2023-logo-div {
    position: absolute;
    left: 70px;
    top: 30px;
}

.imo-2023-logo {
    width: 300px;
}

.imo-2023-japan-div {
    position: absolute;
    right: 50px;
    bottom: 50px;
}

.imo-2023-japan {
    width: 300px;
}

#imo-2023-1-div {
    margin-top: 80px;
}

#imo-2004-4-block {
    font-size: 47px;
    margin-top: 90px;
    margin-bottom: 20px;
}

#imo-2023-4-block {
    margin-top: 20px;
    font-size: 44px;
    margin-bottom: 20px;
}

#usa-egmo-tst-2020-1-text {
    position: absolute;
    left: 40px;
    width: 700px;
    font-size: 52px;
    text-align: left;
}

#usa-egmo-tst-2020-1-img-div {
    position: absolute;
    right: 0px;
}

#usa-egmo-tst-2020-1-img {
    width: 600px;
}

#usa-egmo-tst-202-1-to-prove {
    font-size: 65px;
}

#imo-2004-4-text {
    font-size: 65px;
}

#elmo-2023-1-block {
    margin-top: 30px;
    font-size: 80px;
}

#iran-mo-2021-n2 {
    margin-top: 100px;
    font-size: 75px;
}

#bmo-2023-1 {
    font-size: 75px;
}

#bmo-2023-1-block {
    font-size: 80px;
}

.high-vis {
    color: #c597fc;
}

#usa-tstst-2023-6-text {
    position: absolute;
    left: 40px;
    font-size: 60px;
    width: 600px;
}

#usa-tstst-2023-6-img {
    width: 700px;
}

#usa-tstst-2023-6-img-div {
    position: absolute;
    right: 40px;
}

#imoc-2023-n5-tn {
    width: 1200px;
    text-align: center;
    font-size: 55px;
    display: flex;
    flex-direction: column;
}

#imoc-2023-n5-tn * {
    font-family: "Computer Modern";
}

#imoc-2023-n5-chapter {
    width: 800px;
    align-items: end;
    align-self: flex-end;
    font-family: 'Patrick Hand SC', cursive;
    color: #F9B661;
    font-size: 60px;
    transform: translateY(-50px);
}

#imoc-2023-n5-text {
    font-size: 52px;
}

#imoc-2023-n5-block {
    font-size: 65px;
}

#imoc-2023-n5-main {
    transform: translate(0px, -20px);
}

.imoc-2023-taiwan {
    position: absolute;
    top: 45px;
    left: 45px;
}

.imoc-2023-taiwan-img {
    position: absolute;
    height: 200px;
}

#apmo-2023-4-chapter {
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 900px;
    align-items: end;
    align-self: flex-end;
    font-family: 'Patrick Hand SC', cursive;
    color: #F9B661;
    font-size: 70px;
}

#apmo-2023-4 {
    margin-top: 60px;
    font-size: 65px;
}



#imc-2023-8-comment {
    margin-top: 10px;
    font-family: 'Patrick Hand SC', cursive;
    color: #fff200;
    font-size: 45px;
}

#imoc-2023-a1-block {
    font-size: 120px;
    margin-top: -100px;
}

#olympiad-problem-4-block {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 50px;
}

#olympiad-problem-4-chapter {
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 800px;
    align-items: end;
    align-self: flex-end;
    font-family: 'Patrick Hand SC', cursive;
    color: #f55dd9;
    font-size: 55px;
    border: solid 1mm #f55dd9;
}

#olympiad-problem-4-chapter * {
    color: #f55dd9;
}

#memo-2023-t7-text {
    margin-top: 0px;
    margin-right: 300px;
    font-size: 110px;
}

#memo-2023-t7-block {
    margin-top: -70px;
    margin-left: 300px;
    font-size: 120px;
}

#egmo-2021-2-text {
    margin-top: 0px;
    margin-right: 300px;
    font-size: 70px;
}

#egmo-2021-2-block {
    margin-top: 0px;
    margin-left: 100px;
    font-size: 80px;
}

#olympiad-problem-5-div {
    display: flex;
}

#olympiad-problem-5-text-div {
    margin-top: 100px;
}

#olympiad-problem-5-img {
    width: 550px;
}

#incircle-tangents {
    width: 400px;
}

#swiss-mo-2023-3-block {
    margin-top: -70px;
    margin-bottom: -50px;
    font-size: 80px;
}

#usa-tst-2024-1-block-1 {
    margin-top: -40px;
}

#usa-tst-2024-1-block-2 {
    margin-top: -90px;
}

#imo-1999-4-block {
    font-size: 100px;
}

#baltic-way-2023-4-block {
    font-size: 75px;
    margin-bottom: -170px;
    margin-top: -40px;
}

.cno-2023-4-block {
    font-size: 60px;
}

#imo-1977-6-block {
    font-size: 80px;
}

#belarus-mo-2019-7-block {
    margin-top: 20px;
    font-size: 71px;
}

#brazil-tst-2023-4-4-tn {
    margin-top: 20px;
    font-size: 90px;
}

#china-tst-2022-1-2-tn {
    margin-top: 20px;
    font-size: 60px;
}

#usa-tst-2024-6-info {
    font-size: 40px;
    margin-bottom: 60px;
}

#usa-tst-2024-6-info * {
    color: darkslategray;
}

#usa-tst-2024-6-block {
    font-size: 60px;
}

#china-tst-2016-1-4-block {
    font-size: 80px;
}

#france-tst-2002-4-first {
    font-size: 45px;
    margin-bottom: 30px;
}

#france-tst-2002-4-prove {
    font-family: 'Patrick Hand SC', cursive;
    color: #F58137;
    font-size: 60px;
}

#china-tst-2016-1-6 {
    font-size: 78px;
}

#china-tst-2016-1-6-text {
    text-indent: 300px;
}

#iran-tst-2015-2-img-div {
    position: absolute;
    right: -40px;
    top: 50px;
    bottom: 20px;
    width: 900px;
}

#iran-tst-2015-2-img-div * {
    width: 850px;
}


#iran-tst-2015-2-div {
    position: absolute;
    left: 50px;
    top: 250px;
    bottom: 50px;
    width: 400px;
}

#baltic-way-2018-19-block {
    font-size: 73px;
    margin: -50px 0;
}

#isl-2012-n8-text {
    font-size: 70px;
}

#isl-2012-n8-block {
    margin: -80px 0;
    font-size: 120px;
}

#usamo-2024-1-block {
    font-size: 80px;
}

#china-tst-2024-4 {
    font-size: 130px;
    margin-top: -120px;
}

#izho-2024-3-block {
    font-size: 100px;
    margin: -80px;
}

#izho-2024-3-text {
    font-size: 65px;
}

#imo-2023-5-div {
    position: absolute;
    top: 180px;
    left: 50px;
    width: 600px;
    font-size: 50px;

}

.imo-2023-japanese-triangle-div {
    position: absolute;
    right: 150px;
    bottom: 20px;
}

.imo-2023-japanese-triangle {
    height: 550px;
}

#egmo-2024-3-div {
    font-size: 45px;
}

.egmo-2024-3-blocks {
    font-size: 60px;
    margin: -35px 0;
}

.egmo-2024-div {
    position: absolute;
    right: 0px;
    bottom: 20px;
}

.egmo-2024 {
    width: 400px;
}

#bmo-2024-3-block-1 {
    font-size: 70px;
}

#bmo-2024-3-block-2 {
    margin: -120px -200px 0 0;
    font-size: 110px;
}

#rmm-2024-4-text {
    margin-top: -60px;
    font-size: 85px;
}

#rmm-2024-4-block {
    margin: -150px -440px 0 0;
    font-size: 140px;
}

#malaysia-apmo-selection-2024-3-tn {
    margin-top: -40px;
    font-size: 70px;
}

#rmm-sl-2018-c3-text {
    width: 600px;
    margin: 80px 0 0 -30px;
}

.rmm-sl-2018-c3-img {
    position: absolute;
    right: 60px;
    bottom: 20px;
}

#rmm-final-scores {
    color: #50C878;
}

#rmm-all-games {
    color: #B6321C;
}

#radical-axis {
    height: auto;
    width: auto;
    max-height: 400px;
    max-width: 100%;
}

#cross-ratios {
    height: auto;
    width: auto;
    max-height: 400px;
    max-width: 100%;
}

#projective-map-circle-circle {
    height: auto;
    width: auto;
    max-height: 400px;
    max-width: 100%;
}

#imo-2024-1-block {
    font-size: 80px;
}

.imo-2024-logo-div {
    position: absolute;
    left: 10px;
    top: 10px;
}

.imo-2024-logo {
    width: 300px;
}

#imo-2024-2-div {
    font-size: 50px;
    margin: 50px 0;
}

#imo-2024-2-block {
    margin: -50px 0;
    font-size: 90px;
}

#isl-2023-a4-block-1 {
    font-size: 80px;
    position: absolute;
    top: 200px;
    left: 150px;
}

#isl-2023-a4-block-2 {
    font-size: 160px;
    position: absolute;
    top: 200px;
    left: 550px;
}

#isl-2023-a4-block-3 {
    font-size: 80px;
    position: absolute;
    top: 500px;
    right: 50px;
}

#inmo-2024-4-div2 {
    font-size: 80px;
}

#usamo-1982-4-div {
    font-size: 80px;
}

#usamo-1982-4-block {
    font-size: 130px;
    margin: -130px 0;
}

.imo-2024-uk-div {
    position: absolute;
    right: 50px;
    bottom: 250px;
}

#imo-2024-4-div1 {
    position: absolute;
    font-size: 50px;
    right: 20px;
    bottom: 20px;
}

#imo-2024-4-div2 {
    margin-right: 200px;
}

#egmo-2023-6-div1 {
    position: absolute;
    font-size: 70px;
    left: 20px;
    bottom: 200px;
    max-width: 600px;
}

#egmo-2023-6-div2 {
    margin-left: 600px;
    margin-top: -80px;
}

.egmo-2023-6-img {
    max-height: 600px;
}

#egmo-2023-6-colored {
    color: #D77F65;
}

.cheat {
    display: flex;
}

.slide-content .cheat-column {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    border: #EF7C8E solid 1px;
    width: auto;
    width: 650px;
}

.slide-content .statement-column {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    width: auto;
    width: 650px;
}

.slide-content#union-bound {
    margin-top: 8px;
    font-size: 55px;
    text-align: center;
}

.slide-content#pie {
    margin-top: 20px;
    text-align: center;
    font-size: 40px;
}

.slide-content#trafo-formula {
    text-align: center;
    margin-top: 10px;
    font-size: 44px;
}

#trafo-formula {
    margin-top: 40px;
}

#probability-intro * {
    color: antiquewhite !important;
}

#probability-intro #example-isl {
    position: absolute;
    top: 420px;
    left: -180px;
    transform: scale(0.5) rotate(16deg);
    opacity: 0.7;
}

#probability-intro #example-isl #implication {
    position: absolute;
    top: 50px;
    left: 90px;
    transform: rotate(150deg);
}

#probability-intro #example-imo {
    position: absolute;
    bottom: 50px;
    right: 50px;
    opacity: 0.7;
    transform: rotate(-16deg);
}

#probability-intro #example-imo #example-imo-img {
    width: 300px;
}

#probability-intro #example-linearity {
    position: absolute;
    top: 30px;
    right: -200px;
    transform: scale(0.5) rotate(10deg);
    opacity: 0.65;
}

#probability-intro #example-markov {
    position: absolute;
    bottom: 360px;
    right: -10px;
    transform: scale(0.5) rotate(3deg);
    opacity: 0.65;
}

#probability-intro #example-chebyshev {
    position: absolute;
    bottom: 130px;
    right: 130px;
    transform: scale(0.5) rotate(-8deg);
    opacity: 0.65;
}

#probability-intro #title-div {
    position: absolute;
    top: 120px;
    left: 30px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

#probability-intro #title {
    font-size: 100px;
    font-family: 'Computer Modern', sans-serif;
    font-weight: bold;
}

#probability-intro #subtitle {
    font-size: 80px;
    font-family: 'Computer Modern', sans-serif;
    font-weight: bold;
}

#bwm-2020-2-4 * {
    color: antiquewhite !important;
}

#bwm-2020-2-4 #title {
    position: absolute;
    top: 50px;
    left: 50px;
    font-size: 60px;
    font-family: 'Computer Modern', sans-serif;
    font-weight: bold;
    text-align: left;
}

#bwm-2020-2-4-img {
    position: absolute;
    right: 40px;
    bottom: 40px;
}

#bwm-2020-2-4-img {
    width: 700px;
}

#bwm-2020-2-4-text-div {
    border: #bb86fc solid 1px;
    font-size: 50px;
    padding: 0 10px;
    position: absolute;
    left: 50px;
    top: 300px;
    width: 500px;
    text-align: left;
}

#taiwan-mo-2023-5 * {
    color: antiquewhite;
}

#taiwan-mo-2023-5 #block1,
#taiwan-mo-2023-5 #block2,
#taiwan-mo-2023-5 #block3,
#taiwan-mo-2023-5 #block4,
#taiwan-mo-2023-5 #block5 {
    font-size: 60px;
    position: absolute;
}

#taiwan-mo-2023-5 #block1 {
    top: 150px;
    left: 200px;
}

#taiwan-mo-2023-5 #block2 {
    top: 150px;
    right: 200px;
}

#taiwan-mo-2023-5 #block3 {
    top: 350px;
    left: 200px;
}

#taiwan-mo-2023-5 #block4 {
    top: 350px;
    right: 200px;
}

#taiwan-mo-2023-5 #block5 {
    font-size: 100px;
    top: 490px;
    right: 250px;
    color: #bb86fc !important;
}

#imo-2024-6 {
    padding-top: 15px;
}

#imo-2024-6 #block {
    font-size: 80px;
    margin: -90px;
}

#imo-2024-6 #text {
    font-size: 45px;
}

#isl-2023-n8 #text {
    position: absolute;
    left: 100px;
    top: 200px;
    font-size: 50px;
}

#isl-2023-n8 #block {
    position: absolute;
    font-size: 90px;
    bottom: 160px;
    right: 20px;
}

#bmo-2024-4 #block {
    font-size: 76px;
}

#bmo-2024-4 #text {
    margin-top: 50px;
    font-size: 57px;
}

#bmo-2024-4 #title {
    position: absolute;
    top: 25px;
    left: 50px;
    font-size: 100px;
    font-family: 'Computer Modern', sans-serif;
    font-weight: bold;
    text-align: left;
}

#memo-2024-t6-img-div {
    position: absolute;
    right: -40px;
    top: 50px;
    top: 150px;
    width: 900px;
}

#memo-2024-t6-img-div * {
    width: 670px;
}


#memo-2024-t6-div {
    position: absolute;
    left: 10px;
    top: 200px;
    bottom: 50px;
    width: 600px;
    font-size: 100px;
}

#memo-2024-t6-div * {
    margin-top: 10px;
}

#memo-2024-t5-img-div {
    position: absolute;
    right: -100px;
    top: 50px;
    top: 160px;
    width: 900px;
}

#memo-2024-t5-img-div * {
    width: 560px;
}


#memo-2024-t5-div {
    position: absolute;
    left: 10px;
    top: 200px;
    bottom: 50px;
    width: 700px;
    font-size: 110px;
}

#memo-2024-t5-div * {
    margin-top: 10px;
}

#memo-2024-t8 {
    font-size: 70px;
}
#memo-2024-t8 #block {
    font-size: 130px;
    margin: -110px;
}

#taiwan-mo-2023-5-vt {
    font-size: 30px;
}

#exp-reminder {
    font-size: 38px;
}

#var-reminder {
    font-size: 29px;
}

#usemo-2024-2 {
    font-size: 80px;
}

#memo-2024-t4-vt {
    font-size: 30px;
}

#memo-2024-t4-block {
    font-size: 120px;
    margin: -100px;
}

#memo-2024-t4 {
    font-size: 59px;
    margin-top: 10px;
}

#hk-tst-2024-2-4 {
    font-size: 60px;
}

#hk-tst-2024-2-4 #block1 {
    font-size: 90px;
    position: absolute;
    left: 100px;
    top: 200px;
}
#hk-tst-2024-2-4 #block2 {
    font-size: 90px;
    position: absolute;
    left: 550px;
    top: 300px;
    transform: rotate(45deg);
}
#hk-tst-2024-2-4 #block3 {
    font-size: 90px;
    position: absolute;
    right: 100px;
    top: 400px;
}

#cno-2024-2-img {
    width: 100%;
}

#cno-2024-2-img-div {
    position: absolute;
    right: 10px;
    bottom: 50px;
    width: 820px;
}

#cno-2024-2-div {
    position: absolute;
    left: 30px;
    top: 200px;
    width: 600px;
    text-align: left;
}

.cno-2024-2-block {
    margin: -100px 0;
}

#usamo-2024-6 {
    font-size: 58px;
    margin-top: -30px;
}

#usamo-2024-6 #block {
    font-size: 65px;
    margin: -40px 0;
}

#cno-2024-5-block1 {
    font-size: 90px;
    position: absolute;
    bottom: 180px;
    left: 80px;
}
#cno-2024-5-block2 {
    font-size: 90px;
    position: absolute;
    bottom: 150px;
    left: 480px;
    transform: rotate(20deg);
}
#cno-2024-5-block3 {
    font-size: 90px;
    position: absolute;
    bottom: 40px;
    right: 40px;
}

#tn-red-text {
    color: #EF7C8E;
}

#usamo-2024-6-vt {
    font-size: 30px;
}

#usamo-2024-6 #block {
    margin-top: 10px;
}

#cno-2024-5-vt {
    font-size: 28px;
}

#turkey-mo-2024-3-block {
    font-size: 80px;
}

#usatstst2016-2-img-div {
    margin-top: -60px;
}

#usatstst2016-2-img {
    height: 480px;
}

#usatstst2106-2-vt {
    font-size: 28px;
}

#israel-tst-2025-2-img-div {
    margin-top: -65px;
}

#israel-tst-2025-2-img {
    height: 590px;
}

#japan-mo-2025-4 {
    margin-top: 40px;
    font-size: 60px;
}

#japan-mo-2025-4-block {
    font-size: 100px;
}

#elo-theorem-block {
    font-size: 110px;
    margin-top: -80px;
}

#elo-theorem {
    margin-top: 40px;
}