h1 {
    font-size: 40px !important;
    margin: 5px 0 !important;
}

h2 {
    font-size: 20px !important;
    margin: 5px 0 !important;
}

.content-box {
    background-color: #231e29;
    border-radius: 32px;
    margin: 16px 0;
    padding: calc(max(15px, min(-20px + 3vw, 30px)));
}

.foreground-div {
    background-color: #342941;
    padding: calc(min(5px + 2vw, 30px));
    border-radius: 16px;
}

.content-box-title {
    font-size: 20px;
    font-weight: normal;
}

.content-box2 {
    background-color: #2a2333;
    border-radius: 32px;
    margin: 8px;
    padding: 16px;
}

.primary-text {
    color: #bb86fc;
    text-decoration: none;
    border-radius: 4px;
}

.primary-text:hover {
    color: #ffffff;
    background-color: #bb86fc;
    text-decoration: none;
}

.primary-color {
    color: #bb86fc !important;
}

.content-page {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.secondary-text {
    color: #028376;
    text-decoration: none;
    border-radius: 4px;
}

.secondary-text:hover {
    color: #ffffff;
    background-color: #028376;
    text-decoration: none;
}

.relative {
    position: relative;
}

.clickable-icon {
    color: #bb86fc;
    cursor: pointer;
}

.clickable-icon:hover {
    transition: 450ms;
    color: #03dac5;
}

.non-clickable-icon {
    color: #bb86fc;
}